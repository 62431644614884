/*
==================================================
 featurePanelsSlider.js
==================================================
*/

let featurePanels, freeFeaturePanel, proFeaturePanel, freeFeatureButton, proFeatureButton;

const featurePanelsSlider = {

  init: function (opts) {
    featurePanels = opts.container;
    freeFeaturePanel = featurePanels.querySelector('.feature-panel.free-features');
    proFeaturePanel = featurePanels.querySelector('.feature-panel.pro-features');
    freeFeatureButton = featurePanels.querySelector('.feature-panel .pro-header .mobile-clickable-header.free');
    proFeatureButton = featurePanels.querySelector('.feature-panel .free-header .mobile-clickable-header.pro');

    /*
    ==================================================
    WIRE DOM ELEMENTS
    ==================================================
    */

    if (freeFeatureButton) {
      freeFeatureButton.addEventListener('click', () => {
        this.handleFreeFeatureButtonClick();
      });
    }

    if (proFeatureButton) {
      proFeatureButton.addEventListener('click', () => {
        this.handleProFeatureButtonClick();
      });
    }
  },

  /*
  ==================================================
  EVENT HANDLERS
  ==================================================
  */

  handleFreeFeatureButtonClick() {
    this.showFreeFeaturePanel();
  },

  handleProFeatureButtonClick() {
    this.showProFeaturePanel();
  },
    
  /*
  ==================================================
  LOCAL UTILS
  ==================================================
  */

  showFreeFeaturePanel() {
    if (freeFeaturePanel.classList.contains('.hidden')) {
      freeFeaturePanel.classList.remove('hidden');
    }

    freeFeaturePanel.classList.remove('hidden');
    proFeaturePanel.classList.add('hidden');
  },

  showProFeaturePanel() {
    if (proFeaturePanel.classList.contains('.hidden')) {
      proFeaturePanel.classList.remove('hidden');
    }

    proFeaturePanel.classList.remove('hidden');
    freeFeaturePanel.classList.add('hidden');
  },
}

export default featurePanelsSlider;