
import React      from 'react';
import ReactDOM   from 'react-dom';
import SearchBar  from '_views/shared/SearchBar';

let mountPoint;

const marketSearchBar = {

  init: function(opts) {
    /*
      opts: {
        onFocus, // event handler
        selector,  // the elem where to mount the search bar
        shouldSuppressInitialFocus,  // set to true to avoid default behavior of giving the searchbox focus
      }
    */

    /*
    ==================================================
     CACHE DOM ELEMENTS
    ==================================================
    */

    mountPoint = document.querySelector(opts.selector);


    /*
    ==================================================
     MOUNT REACT COMPONENT
    ==================================================
    */

    if (mountPoint) {
      ReactDOM.render(
        <SearchBar 
          onFocus={opts.onFocus}
          shouldSuppressInitialFocus={opts.shouldSuppressInitialFocus}
        />, mountPoint
      );
    }
  },
}

export default marketSearchBar;
