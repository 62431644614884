
import React from 'react';
import ReactDOM from 'react-dom';
import VideoModal from '_views/modals/VideoModal';

const videoModal = {

  render: function (opts) {

    const DEFAULT_VIDEO_URL = 'https://www.youtube.com/embed/mAxlGrrEsYs';
    const mountPoint = document.querySelector(opts.selector);
    const videoUrl = opts.videoUrl || DEFAULT_VIDEO_URL;

    
    /*
    ==================================================
     MOUNT REACT COMPONENT
    ==================================================
    */

    if (mountPoint) {
      ReactDOM.render(
        <VideoModal
          isOpen={opts.isOpen}
          onCloseRequest={() => opts.onCloseRequest()} 
          shouldAutoPlay={opts.shouldAutoPlay}
          videoUrl={videoUrl}
         />, mountPoint
      );
    }
  },
}

export default videoModal;
