
import DropWrapper       from '_views/shared/DropWrapper';
import EventManager      from '@brainscape/event-manager';
import IconTextButton    from '_views/shared/IconTextButton';
import PropTypes         from 'prop-types';
import React             from 'react';
import TransitionWrapper from '_views/shared/TransitionWrapper';
import UiHelper          from '_utils/UiHelper';
import {CloseButton}     from '_views/shared/IconButton';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses:               PropTypes.string,
  isDroppable:              PropTypes.bool,
  isOpen:                   PropTypes.bool,
  isUndismissable:          PropTypes.bool,
  onClosed:                 PropTypes.func,
  onCloseRequest:           PropTypes.func,
  onResolveRequest:         PropTypes.func,
  size:                     PropTypes.string, // narrow, default, large
  shouldIgnoreOverlayClick: PropTypes.bool,
  shouldResolveOnEnterKey:  PropTypes.bool,
  showBackButton:           PropTypes.bool,
  onBackIconButtonClick:    PropTypes.func,
};

let internalModalCount = 0;
const modalCount = () => internalModalCount;


class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {shouldRender: this.props.isOpen};
    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    UiHelper.adjustViewportHeight();

    if (this.props.isOpen) {
      this.openModal();
    }

    document.addEventListener('keydown', this.handleKeyDown, true);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.openModal();
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.closeModal();
    }
  }

  componentWillUnmount() {
    this.events.disable();

    if (this.props.isOpen) { 
      this.publishModalClosed();
      internalModalCount -= 1; 
    }

    document.removeEventListener('keydown', this.handleKeyDown, false);
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.state.shouldRender) {
      return null;
    }

    const hasBackButton = this.props.showBackButton ? 'has-back-button' : '';
    const classes = toClassStr(['modal', hasBackButton, this.props.size, this.props.addClasses]);

    return (
      <TransitionWrapper
        addClasses="modal-transition-wrapper"
        onTransitionedOut={() => this.handleTransitionedOut()}
        shouldTransitionIn={this.props.isOpen}
        shouldTransitionOut={!this.props.isOpen}
        transitionInDelay={100}
        transitionInDuration={500}
        transitionOutDuration={500}
      >

        <div className={classes}>
          <div className="modal-overlay" onClick={(e) => this.handleOverlayClick(e)}></div>
          {this.renderModalBody()}
        </div>

      </TransitionWrapper>
    );
  }

  renderModalBody() {
    if (this.props.isDroppable) {
      return (
        <DropWrapper
          addClasses={this.props.addDropWrapperClasses}
          isDisabled={this.props.isDropWrapperDisabled}
          onFileDragEnter={this.props.onFileDragEnter}
          onFileDrop={this.props.onFileDrop}
        >
          {this.renderModalBodyCore()}
        </DropWrapper>
      );
    }

    return this.renderModalBodyCore();
  }

  renderModalBodyCore() {
    return (
      <div className="modal-body" onClick={(e) => this.handleModalBodyClick(e)}>
        {this.props.showBackButton && this.renderBackButton()}
        <div className="mobile-branding">Brainscape</div>
        {this.renderCloseButton()}
        <div className="modal-content">
          {this.props.children}
        </div>
      </div>
    );
  }

  renderCloseButton() {
    if (this.props.isUndismissable) {
      return null;
    }

    return (
      <CloseButton
        addClasses="close-modal-button"
        onClick={() => this.handleCloseButtonClick()}
      />
    );
  }

  renderBackButton() {
    if (!this.props.showBackButton) {
      return null;
    }

    return (
      <IconTextButton 
        addClasses={"back-button"}
        iconClass="ion-chevron-left"
        label="Back"
        onClick={this.props.onBackIconButtonClick}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCloseButtonClick = () => {
    this.handleCloseRequest();
  };

  handleCloseRequest = () => {
    if (this.props.isUndismissable) {
      return false;
    }

    this.props.onCloseRequest();
  };

  handleKeyDown = (e) => {
    if (!this.props.isOpen) {
      return false;
    }

    if (e.keyCode == 32) {
      // space bar
      e.stopPropagation();
    }
    
    if (e.keyCode === 27) {
      // esc
      this.events.ifEnabled(this.handleCloseRequest);

      e.stopPropagation();
      return true;
    }

    if (e.keyCode === 13) {
      // enter
      if (this.props.shouldResolveOnEnterKey && this.props.onResolveRequest) {
        this.props.onResolveRequest();
      }

      e.stopPropagation();
      return true;
    }
  };

  handleModalBodyClick = (e) => {
    if (e) { 
      e.stopPropagation(); 
    }
  };

  handleOverlayClick = (e) => {
    e.stopPropagation();

    if (this.props.shouldIgnoreOverlayClick) {
      return false;
    }

    this.handleCloseRequest();
  };

  handleTransitionedOut = () => {
    this.closeModal();
  };


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishModalOpened = () => {
    EventManager.emitEvent('modal:opened', {});
  }

  publishModalClosed = () => {
    EventManager.emitEvent('modal:closed', {});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  adjustViewportHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  closeModal = () => {
    internalModalCount -= 1;

    this.setState({
      shouldRender: false,
    }, () => {
      this.publishModalClosed();

      if (this.props.onClosed) {
        this.props.onClosed();
      }
    });
  }

  openModal = () => {
    internalModalCount += 1;

    this.setState({
      shouldRender: true,
    }, () => {
      this.publishModalOpened();
    });
  }
};

Modal.propTypes = PT;

export {Modal as default, modalCount};
