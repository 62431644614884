
import mainPublic     from '../../core/main-public';
import landingPage    from '../pages/landing/landing-page';

// Initialize the main public module and the landing page module  
if (document.readyState === 'loading') {
  // The DOM is still loading, set up the event listener
  document.addEventListener("DOMContentLoaded", () => {
    mainPublic.init();
    landingPage.init();
  });
} else {
  // The DOM has already loaded, initialize immediately
  mainPublic.init();
  landingPage.init();
}
