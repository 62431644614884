/*
==================================================
CAROUSEL-GRID.JS
==================================================
*/

import UiHelper     from '_utils/UiHelper';

let carouselList, carouselSlide, isBreakpoint, carouselExit, nestedCarousel, nestedCarouselList, nestedCarouselSlide, sliderInterval,
  isDown, startX, scrollLeft, tapToOpenIcon;

const carouselGrid = {

  init: function (opts) {
    /*
    ==================================================
    CACHE DOM ELEMENTS
    ==================================================
    */

    carouselList = opts.carouselContainer.querySelector('.carousel-grid-list');
    carouselSlide = carouselList.querySelectorAll('.carousel-grid-slide');
    carouselExit = carouselList.querySelector('.carousel-grid-exit');
    nestedCarousel = carouselList.querySelector('.nested-carousel');
    nestedCarouselList = nestedCarousel.querySelector('.nested-carousel-list');
    nestedCarouselSlide = nestedCarouselList.querySelectorAll('.nested-carousel-slide');
    tapToOpenIcon = opts.carouselContainer.querySelector('.tap-to-open-icon');
    isBreakpoint = opts.breakpoint;
    isDown = false;
    startX;
    scrollLeft;

    /*
  ==================================================
  WIRE DOM ELEMENTS
  ==================================================
  */

    if (carouselList) {
      window.addEventListener('load', (e) => {
        if (window.innerWidth < isBreakpoint) {
          this.handleLoadAction(e);
        }
      });

      window.addEventListener('resize', (e) => {
        if (window.innerWidth < isBreakpoint) {
          this.handleLoadAction(e);
        }
      });
    }

    if (tapToOpenIcon) {
      document.addEventListener("scroll", () => {
        this.handleDocumentScroll(tapToOpenIcon);
      });
    }

    if (nestedCarouselList) {
      nestedCarouselList.addEventListener('mousedown', (e) => {
        this.handleMouseDown(e);
      });

      nestedCarouselList.addEventListener('mouseleave', () => {
        this.handleMouseLeave();
      });

      nestedCarouselList.addEventListener('mouseup', (e) => {
        this.handleMouseUp(e);
      });

      nestedCarouselList.addEventListener('mousemove', (e) => {
        this.handleMouseMove(e);
      });
    }

    if (carouselExit) {
      carouselExit.addEventListener('click', (e) => {
        this.handleExitCarousel();
      });
    }

  },

/*
==================================================
EVENT HANDLERS
==================================================
*/

  handleLoadAction(e) {
    e.preventDefault();
    this.addClickHandlerToSlides();
  },

  handleMouseDown(e) {
    this.onMouseDown(e);
  },

  handleMouseLeave() {
    this.onMouseLeave();
  },

  handleMouseUp() {
    this.onMouseUp();
  },

  handleMouseMove(e) {
    this.onMouseMove(e);
  },

  handleExitCarousel() {
    this.exitCarousel();
  },

  handleDocumentScroll(elem) {
    if (elem === tapToOpenIcon) {
      if (UiHelper.isInViewport(tapToOpenIcon)) {
        tapToOpenIcon.classList.add('animate');
      } else {
        tapToOpenIcon.classList.remove('animate');
      }
    }
  },

/*
==================================================
LOCAL UTILS
==================================================
*/

  addClickHandlerToSlides() {
    carouselSlide.forEach((carouselSlide) => {
      carouselSlide.addEventListener('click', (e) => {
        this.showSelectedSlide(carouselSlide);
        this.hideDragIcon();
      });
    });

    nestedCarouselSlide.forEach((nestedCarouselSlide) => {
      nestedCarouselSlide.addEventListener('click', (e) => {
        this.getElementFromId(nestedCarouselSlide);
      });
    });

  },
  getElementFromId(elem) {
    let id = elem.id;

    carouselSlide.forEach((carouselSlide) => {
      if (carouselSlide.id == id) {
        this.showSelectedSlide(carouselSlide);
      }
    });
  },

  showSelectedSlide(elem) {
    carouselSlide.forEach((carouselSlide) => {
      if (carouselSlide.classList.contains('active')) {
        carouselSlide.classList.remove('active');
        carouselSlide.querySelector('.testifier-position-quote').classList.remove('active');
        carouselList.classList.remove('active');
        nestedCarousel.classList.remove('active');
        carouselSlide.classList.remove('inactive');
      }
    });

    carouselSlide.forEach((carouselSlide) => {
      if (carouselSlide == elem) {
        if (!carouselSlide.classList.contains('inactive')) {
          carouselSlide.classList.add('active');
        }
        carouselSlide.classList.replace('inactive', 'active');
        carouselSlide.querySelector('.testifier-position-quote').classList.add('active');
        carouselList.querySelector('.carousel-grid-exit').classList.add('active');
        carouselList.classList.add('active');
        nestedCarousel.classList.add('active');
      }

      if (!carouselSlide.classList.contains('active')) {
        carouselSlide.classList.add('inactive');
      }
    });
    this.removeActiveSlideFromOptions(elem);
  },

  removeActiveSlideFromOptions(elem) {
    nestedCarouselSlide.forEach((nestedCarouselSlide) => {
      if (nestedCarouselSlide.id == elem.id) {
        nestedCarouselSlide.style.display = 'none';
      } else {
        nestedCarouselSlide.style.display = 'block';
      }
    });
  },

  hideDragIcon() {
    let touchIcon = nestedCarousel.querySelector('.drag-to-scroll-icon');
    if (nestedCarousel.classList.contains('active')) {
        sliderInterval = setInterval(() => {
          touchIcon.style.display = 'none';
        }, 4000);
    }
  },

  onMouseDown(e) {
    isDown = true;
    startX = e.pageX - nestedCarouselList.offsetLeft;
    scrollLeft = nestedCarouselList.scrollLeft;
  },

  onMouseLeave(e) {
    isDown = false;
  },

  onMouseUp(e) {
    isDown = false;
  },

  onMouseMove(e) {
    if (!isDown) return;
    e.preventDefault();
    const posInitial = e.pageX - nestedCarouselList.offsetLeft;
    const posFinal = (posInitial - startX) * 2;
    nestedCarouselList.scrollLeft = scrollLeft - posFinal;
  },

  exitCarousel() {
    carouselSlide.forEach((carouselSlide) => {
      if (carouselSlide.classList.contains('active')) {
        carouselSlide.classList.remove('active');
        carouselSlide.querySelector('.testifier-position-quote').classList.remove('active');
        carouselList.querySelector('.carousel-grid-exit').classList.remove('active');
        carouselList.classList.remove('active');
        nestedCarousel.classList.remove('active');
      }
    });
  },

}

export default carouselGrid;
